import Cookies from "js-cookie";
import { Controller } from "stimulus";
import { getActiveCurrency, setActiveCurrency } from "../../util/currency";
import { EventCurrencyChanged } from "../../events";

const IS_DARK = "is_dark";

export default class extends Controller {
  static targets = ["darkModeToggle", "darkModeButton", "currencyText"];

  connect() {
    this._sync_dark_mode_setting();

    this.darkModeEnabled = this._getDarkModeSetting();
    this._refreshDarkMode();

    if (this.hasStatsCardTarget && this.hasStatsCardToggleTarget) {
      this.statsCardEnabled = localStorage.getItem("settings:statsCard") === "true";
      this._refreshStatsCard();
    }

    fx.base = "btc";
    fx.rates = JSON.parse(document.body.getAttribute("data-exchange-rate-json"));

    // Inform initial currency load (with delay to ensure others receive it).
    setTimeout(() => this._refreshCurrency(), 50);
  }

  toggleDarkMode() {
    this.darkModeEnabled = !this.darkModeEnabled;

    this._setDarkModeSetting();
    this._refreshDarkMode();
  }

  _refreshDarkMode() {
    this.darkModeToggleTargets.forEach(target => {
      target.checked = this.darkModeEnabled;
    });

    this.darkModeButtonTarget.classList.toggle("fa-sun", !this.darkModeEnabled);
    this.darkModeButtonTarget.classList.toggle("fa-moon", this.darkModeEnabled);

    // TODO: Remove 'darktheme' class usage after fully migrated to AppV2.
    document.body.classList.toggle("darktheme", this.darkModeEnabled);
    document.body.classList.toggle("tw-dark", this.darkModeEnabled);

    const swaggerCSS = document.getElementById("swagger-css-link")
    if (swaggerCSS) {
      swaggerCSS.href = this.darkModeEnabled ? "/api/documentations/swagger-ui-dark.css" : "/api/documentations/swagger-ui.css";
    }
  }

  changeCurrency(e) {
    const currencyCode = e.currentTarget.getAttribute("data-iso-code");

    setActiveCurrency(currencyCode);
    this._refreshCurrency(currencyCode);

    Modal.hide("currency_selector");
  }

  _refreshCurrency(currencyCode = getActiveCurrency()) {
    // Inform others about currency change.
    window.dispatchEvent(new CustomEvent(EventCurrencyChanged, { detail: { currencyCode } }));

    // Update currency selector to reflect new selected currency.
    document.querySelector("#currencySelector .fa-check")?.classList?.remove("fa-check");
    document.querySelector(`#currencySelector [data-iso-code='${currencyCode}'] > i`)?.classList.add("fa-check");
    document.querySelector(`#currencySelector [data-iso-code].tw-bg-gray-100`)?.classList.remove("tw-bg-gray-100", "dark:tw-bg-moon-700");
    document.querySelector(`#currencySelector [data-iso-code='${currencyCode}']`)?.classList.add("tw-bg-gray-100", "dark:tw-bg-moon-700");

    // Update all currency text targets to reflect new currency.
    this.currencyTextTargets.forEach(target => {
      target.textContent = currencyCode.toUpperCase()
    });
  }

  _sync_dark_mode_setting() {
    if (localStorage.getItem(IS_DARK) !== null) return;

    localStorage.setItem(IS_DARK, Cookies.get(IS_DARK));
  }

  _getDarkModeSetting() {
    let dark_mode = localStorage.getItem(IS_DARK) || Cookies.get(IS_DARK);
    return dark_mode === "true";
  }

  _setDarkModeSetting() {
    Cookies.set(IS_DARK, String(this.darkModeEnabled), { expires: 365 });
    localStorage.setItem(IS_DARK, String(this.darkModeEnabled));
  }
}
