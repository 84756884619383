import { formatCurrency, getActiveCurrency } from "../../util/currency";
import { buildChartDefs, drawWatermark } from "./generic_chart";

const _hasData = (data) => data != null && data.length >= 1;
const _1_HOUR_IN_MS = 1 * 60 * 60 * 1000;
const _1_THOUSAND = 1000;

const EXPONENTS_CONFIG = {
  maximumDecimalTrailingZeroes: 1,
  significantFigures: 4,
};

const STABLECOIN_CONFIG = {
  maximumDecimalTrailingZeroes: 4,
  significantFigures: 4,
};

const formatterDateTime = new Intl.DateTimeFormat(I18n.locale, {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
  hourCycle: 'h23',
  timeZoneName: 'short',
});

export const buildChartAxes = (currencyCode, scaleType, hasVolumeData, stablecoin, ...secondaryData) => {
  secondaryData = secondaryData.filter(x => !!x);
  const chartAxes = [];

  // Main data
  chartAxes.push({
    type: scaleType,
    showLastLabel: true,
    labels: {
      align: "left",
      reserveSpace: true,
      useHTML: true,
      formatter() {
        let config;

        if (stablecoin === "true") {
          config = STABLECOIN_CONFIG;
        } else {
          config = this.value < 1 ? EXPONENTS_CONFIG : false
        }

        return formatCurrency(this.value, currencyCode, false, config, this.value >= _1_THOUSAND);
      }
    },
    height: "80%",
  });

  // Volume data
  if (hasVolumeData) {
    chartAxes.push({
      type: scaleType,
      visible: false,
      height: "20%",
      top: "80%",
    });
  }

  // Secondary data (BTC/ETH)
  for (const data of secondaryData) {
    let secondaryAxis = {
      type: scaleType,
      boostThreshold: 500,
      title: { text: `<span class="highcharts-color-${data.key}">${data.key.toUpperCase()}</span>` },
      opposite: false,
      labels: {
        useHTML: true,
        formatter() {
          return this.value < 0 ? null : formatCurrency(this.value, data.key, false, this.value < 1 ? EXPONENTS_CONFIG : false, this.value >= _1_THOUSAND);
        }
      },
      height: "70%",
      showLastLabel: true,
    };

    chartAxes.push(secondaryAxis);
  }

  return chartAxes;
};


export const buildChartSeries = (mainData, mainLabel, volumeData, ...secondaryData) => {
  secondaryData = secondaryData.filter(x => !!x);
  const chartSeries = [];

  // Main data
  const minPrice = _hasData(mainData) ? mainData[0][1] : 0;
  const maxPrice = _hasData(mainData) ? mainData[mainData.length - 1][1] : 0;
  let mainSeries = {
    name: mainLabel,
    type: _hasData(secondaryData) ? "line" : "area",
    data: mainData,
    colorIndex: _hasData(secondaryData) ? "coin" : (maxPrice >= minPrice ? "positive" : "negative"),
    threshold: -Infinity,
    boostThreshold: _hasData(secondaryData) ? 500 : undefined,
    dataGrouping: { enabled: false },
    yAxis: 0,
    marker: {
      enabled: false,
      radius: 0
    },
    lineWidth: 1,
    states: {
      hover: {
        lineWidth: 1
      }
    },
  };

  chartSeries.push(mainSeries);


  // Volume data
  if (volumeData) {
    let volumeSeries = {
      name: I18n.t("charts.volume"),
      type: "column",
      data: volumeData,
      maxPointWidth: 250,
      boostThreshold: 500,
      dataGrouping: { enabled: false },
      yAxis: 1,
    };

    chartSeries.push(volumeSeries);
  }

  // Handle secondary data (BTC, ETH).
  for (const data of secondaryData) {
    let secondarySeries = {
      name: data.key.toUpperCase(),
      type: "line",
      data: data.data.stats,
      colorIndex: data.key,
      maxPointWidth: 250,
      boostThreshold: 500,
      dataGrouping: { enabled: false },
      yAxis: chartSeries.length,
    };

    chartSeries.push(secondarySeries);
  }

  return chartSeries;
};


export const buildChartConfig = (element, chartAxes, chartSeries, currencyCode = getActiveCurrency(), maxLongerCacheData = null, customDataCallback = null) => {
  return {
    tooltip: {
      useHTML: true,
      borderRadius: 16,
      padding: 0.5,
      formatter() {
        let content = `<div class="title">${formatterDateTime.format(this.x)}</div> `;

        // Loop through all y-axes and display in tooltip.
        for (let point of this.points) {
          let name = point.series.name;
          let currency = currencyCode;

          // Handle additional BTC/ETH axes.
          if (name === "BTC" || name === "ETH") {
            const mainAxisName = chartSeries[0].name;

            name = `${mainAxisName} (${point.series.name})`;
            currency = point.series.name.toLowerCase();
          }

          let value = formatCurrency(point.y, currency)

          if (name === "USD") {
            const mainAxisName = chartSeries[0].name;

            name = `${mainAxisName} (USD)`;
            const formattedUsdCurrency = formatCurrency(point.y, "usd", false, point.y < 1 ? EXPONENTS_CONFIG : false, point.y >= _1_THOUSAND)
            value = formattedUsdCurrency
          }

          // Handle stablecoin >1 fixed to 3 decimals
          if (element.dataset.stablecoin === "true" && name === "Price") {
            value = formatCurrency(point.y, currency, false, STABLECOIN_CONFIG)
          }

          content += `<div>${name}: <span class="value">${value}</span></div>`;
        }

        return content;
      }
    },


    navigator: !maxLongerCacheData ? {
      series: {
        colorIndex: 0,
      },
      height: 40
    } : {
      adaptToUpdatedData: false,
      series: {
        colorIndex: 0,
        data: maxLongerCacheData
      },
      height: 40
    },


    xAxis: {
      minRange: _1_HOUR_IN_MS,
      events: {
        afterSetExtremes: e => {
          if (customDataCallback) customDataCallback(e);
        }
      }
    },
    yAxis: chartAxes,
    series: chartSeries,


    exporting: false,
    rangeSelector: { enabled: false },
    scrollbar: { enabled: false },
    credits: { enabled: false },


    plotOptions: { series: { animation: false } },
    chart: {
      events: { render: (e) => drawWatermark(e) },
      panning: false,
      marginTop: 50,
      renderTo: element.id,
      styledMode: true,
      zoomType: 'x',
    },

    time: { useUTC: false },

    ...buildChartDefs(),
  };
};

export const buildOhlcChartConfig = (element, ohlcData, scaleType, currencyCode = getActiveCurrency()) => {
  return {
    tooltip: {
      useHTML: true,
      borderRadius: 16,
      padding: 12,
      formatter() {
        return `
          <div class="title">${formatterDateTime.format(this.x)}</div>
          <div>O: <span class="value">${formatCurrency(this.points[0].point.open, currencyCode)}</span></div>
          <div>H: <span class="value">${formatCurrency(this.points[0].point.high, currencyCode)}</span></div>
          <div>L: <span class="value">${formatCurrency(this.points[0].point.low, currencyCode)}</span></div>
          <div>C: <span class="value">${formatCurrency(this.points[0].point.close, currencyCode)}</span></div>
        `;
      }
    },


    xAxis: { minRange: _1_HOUR_IN_MS },
    yAxis: [{
      showLastLabel: true,
      type: scaleType,
      labels: {
        align: "left",
        reserveSpace: true,
        useHTML: true,
        formatter() {
          return formatCurrency(this.value, currencyCode, false, this.value < 1 ? EXPONENTS_CONFIG : false, this.value >= _1_THOUSAND);
        }
      },
    }],
    series: [{
      type: "candlestick",
      name: "Coin OHLC",
      data: ohlcData,
      groupPadding: 0.0,
    }],

    navigator: { enabled: false },

    exporting: false,
    rangeSelector: { enabled: false },
    scrollbar: { enabled: false },
    credits: { enabled: false },


    plotOptions: { series: { animation: false } },
    chart: {
      events: { render: (e) => drawWatermark(e, true) },
      panning: false,
      marginTop: 50,
      renderTo: element.id,
      styledMode: true,
      zoomType: 'x',
    },

    time: { useUTC: false }
  };
};
